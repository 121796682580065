import { MailruIcon } from "react-share";
// const authUrlOrigin='http://localhost/aamusted_auth/';

var domainSettings = {
    dbName: 'postitonline',
    dbVersion: 1,
    dbTables: [{
        tblName: 'user',
        tblOpt: { autoIncrement: true },
        tblIndexes: [{ indexName: 'user', indexOpt: { unique: true } }]
    },
    ],
    //P73/V PENKWASE, BS-0080-6744 
    appName: 'Postitonlinegh',
    appMail: 'support@postitonlinegh.com',
    numPopularToAppearOnCategoryTop: 5,
    googleClientUrl: 'https://accounts.google.com/gsi/client',
    googleClientID: '720696234161-050k7elpoios13i2ocfpu7el28k4ae5v.apps.googleusercontent.com',
    facebookAppID: 597121861801078,
    primaryColor: 'pinkx darken-4x special-color-darkx brownx darken-4x teal darken-4',
    secondaryColor: 'pinkx darken-1x special-colorx brownx darken-1x deep-turquiose',
    textColor: 'blue-grey-text',
    primaryColorHex: '#01579b',
    secondaryColorHex: '#1e847f',
    textColorHex: '#607d8b',
   
    // backend: 'https://m.bwehhotels.com/api',//campus
    // logo: 'https://m.bwehhotels.com',//campus
    
    // backend: 'http://localhost/kari/api',//campus
    backend: 'https://kade.karigrup.com/api',//campus
    logo: 'https://kade.karigrup.com',//campus
    
    
    // backend: 'http://192.168.8.100/kari/api',//home
    // logo: 'http://192.168.8.100/kari',//home
    // backend: 'http://192.168.2.82/kari/api',//campus
    // logo: 'http://192.168.2.82/kari',//campus
    // backend: 'http://localhost/kari/api',
    // logo: 'http://localhost/kari',
    
    

    // authUrlOrigin: 'http://localhost/aamusted_auth/',
    // authUrl: `http://localhost/aamusted_auth/tpapi/auth_user`,
    // renewTokenUrl: `http://localhost/aamusted_auth/tpapi/renew_user`,
    // changePasswordUrl: `http://localhost/aamusted_auth/tpapi/change_password`,

    // bankSaveAtAuthUrl: `http://localhost/aamusted_auth/tpapi/save_third_party`,
    // bankDelAtAuthUrl: `http://localhost/aamusted_auth/tpapi/del_third_party`,
    // getBankDetailsFromAuth: `http://localhost/aamusted_auth/tpapi/get_third_party`,
    
    // getAdmin: `http://localhost/aamusted_auth/tpapi/get_admin`,
    // saveAdmin: `http://localhost/aamusted_auth/tpapi/save_admin`,
    // deleteAdmin: `http://localhost/aamusted_auth/tpapi/del_admin`,
}

export default domainSettings;


