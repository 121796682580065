
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Card, Button, DatePicker, message, Tag, Tooltip, Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';
import useModal from '../../hooks/modal';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

const Sales = (props) => {
    const valuesStore = ValuesStore();
    // const edit = useEdit('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const deliveryAdd = useAdd('tables_metadata', 'table_name');
    const balPayment = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const navigate = useNavigate();
    const modal = useModal();
    const printModal = useModal();
    const printableWayBill = useRef();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_sales_table`,
        'post',
        'result',
        'totalCount',
        'id',
        { product_item: 'IN', receipt_no: 'LIKE', phone_no: 'LIKE', 'payment_status': 'IN', delivery_status: 'IN', date_inserted: 'LIKE' },
        { table: 'sales', fields: ['*'] });

    const columns = ([
        {
            title: 'Product',
            dataIndex: 'product_item',
            filterSearch: true,
        },
        {
            title: 'UnitPx',
            dataIndex: 'unit_price',
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
        },
        {
            title: 'ReceiptNo.',
            dataIndex: 'receipt_no',
            ...table.getColumnSearchProps('receipt_no'),
        },
        {
            title: 'ExpAmt',
            dataIndex: 'total_expected_amt',
        },
        {
            title: 'DownPayment',
            dataIndex: 'amount_received',
        },
        {
            title: 'Bal.',
            dataIndex: 'balance',
        },
        {
            title: 'Customer',
            dataIndex: 'phone_no',
            ...table.getColumnSearchProps('phone_no'),
            render: (v, record) => {
                return <Tooltip title={`Name: ${record.customer_name}, Address: ${record.customer_address}`}>{v}</Tooltip>
            }
        },
        {
            title: 'PS',
            dataIndex: 'payment_status',
            filters: [
                {
                    value: 'no_payment',
                    text: 'No Payment'
                },
                {
                    value: 'part_payment',
                    text: 'Part Payment'
                },
                {
                    value: 'full_payment',
                    text: 'Full Payment'
                }
            ],
            render: (v, record) => {
                return <Tag color={v == 'part_payment' ? 'blue-inverse' : v == 'full_payment' ? 'green-inverse' : 'red-inverse'}>{v[0]?.toUpperCase()}</Tag>
            }
        },
        {
            title: 'DS',
            dataIndex: 'delivery_status',
            filters: [
                {
                    value: 'no_delivery',
                    text: 'No Delivery'
                },
                {
                    value: 'part_delivery',
                    text: 'Part Delivery'
                },
                {
                    value: 'full_delivery',
                    text: 'Full Delivery'
                }
            ],
            render: (v, record) => {
                return <Tag color={v == 'part_delivery' ? 'blue-inverse' : v == 'full_delivery' ? 'green-inverse' : 'red-inverse'}>{v[0]?.toUpperCase()}</Tag>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return utils.formatDate(v);
            },
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return record.deactivated ? <em>Deactivated</em> : <Space size="middle">
                    <Tooltip title='Print Reciept'> <Button className='btn-successx border-0x' onClick={e => printReceipt(record)}><i className='fas fa-print text-secondary' /></Button></Tooltip>
                    <Tooltip title='View Waybill'> <Button className='btn-successx border-0x' onClick={e => getWayBills(record)}><i className='fas fa-eye text-info' /></Button></Tooltip>
                    <Popover content={popOverContent(record)} title="Extra Actions" trigger="click">
                        <Button>Extra</Button>
                    </Popover>
                    {/* <Tooltip title='Show Waybills'> <Button className='btn-successx border-0x' onClick={e => balancePayment(record)}><i className='fas fa-eye text-info' /></Button></Tooltip> */}
                    {/* <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'sales')}><i className='fas fa-edit text-success' /></Button> */}
                </Space>
            },
        },
    ]);

    function popOverContent(record) {
        return (
            <Space>
                <Tooltip title='Make Delivery'> <Button className='btn-successx border-0x' onClick={e => delivery(record)}><i className='fas fa-shipping-fast text-primary' /></Button></Tooltip>
                <Tooltip title='Balance Payment'> <Button className='btn-successx border-0x' onClick={e => balancePayment(record)}><i className='fas fa-money-bill text-indigo' /></Button></Tooltip>
                <Tooltip title='View Details'> <Button className='btn-successx border-0x' onClick={e => viewSales(record)}><i className='far fa-credit-card text-primary' /></Button></Tooltip>
                {del.confirm(
                    `${Settings.backend}/deactivate_sales`,
                    record,
                    'Are you sure to deactivate this item. This action cannot be reversed',
                    { tableName: 'sales', where: 'id', whereType: 'closed' },
                    <Tooltip title='Deactivate Sales'> <Button className='btn-dangerx border-0x'><i className='fas fa-trash text-danger' /></Button></Tooltip>
                )}
            </Space>
        );
    }


    async function viewSales(record) {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_sales_payments`, null, { receipt_no: record.receipt_no });
        const salesDetails = [
            ['Sold By', record.sold_by]
            ['Customer Name', record.customer_name],
            ['Customer Phone No.', record.phone_no],
            ['Customer Address', record.customer_address],
            ['Receipt No.', record.receipt_no],
            ['Product', record.product_item],

            ['Unit Price', record.unit_price],            
            ['Actual Quantity', record.actual_qty],
            ['Bonus Quantity', record.bonus_qty],
            ['Total Quantity', record.qty],
            ['Amount To Pay', record.total_expected_amt],
            ['Down Payment', record.amount_received],
            ['Balance', record.balance],

            ['Payment Status', record.payment_status],
            ['Delivery Status', record.delivery_status],
            ['Sales Type', record.sales_type],
            ['Advance Sale', record.is_advance ? 'Yes' : 'No'],
            ['Deactivated', record.deactivated ? 'Yes' : 'No'],
            ['Date', record.date_inserted],
            ['Remark', record.remark]
        ];
        const data = res.results?.map(v => {
            return [v.amt, v.cash_flow, v.recorded_by, v.payment_mode, v.outstanding_bal, v.date_inserted];
        });
        const details = utils.getTable(
            [
                <label className='fw-bold'>Amount</label>,
                <label className='fw-bold'>Flow</label>,
                <label className='fw-bold'>Recorder</label>,
                <label className='fw-bold'>PayMode</label>,
                <label className='fw-bold'>Bal</label>,
                <label className='fw-bold'>Date</label>,
            ],
            data, 'w-100 table table-sm table-striped', '', '', '');

        const details2 = utils.getTable(
            [
                <label className='fw-bold'>Item</label>,
                <label className='fw-bold'>Value</label>,
            ],
            salesDetails, 'w-100 table table-sm table-stripedx', 'fw-bold', '', 'fw-bold');
        modal.setTitle(`Details of ${record.receipt_no} Sales`);
        modal.setContent(
            <div className='row'>
                <div className='col-md-12'>
                    {details2}
                </div>
                <div className='col-md-12'>
                    <p className='fw-bold h5 text-danger'>Payments Made</p>
                    {details}
                </div>
            </div>
        );
        modal.setOpen(true);
    }

    async function printReceipt(record) {
        printReceiptTempl(record);
    }

    async function getWayBills(record) {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_waybills`, null, { receipt_no: record.receipt_no });
        if (!res.results.length) {
            message.info('No waybills found');
            return;
        }
        let totalDipatched = 0;
        let totalOrdered = 0;
        const data = res.results?.map(v => {
            totalDipatched += parseFloat(v.dispatched_qty);
            totalOrdered = parseFloat(v.sales_qty);
            return [v.driver, v.dispatcher, v.truck_no, v.dispatched_qty, v.sales_qty,
            <Space>
                <Tooltip title="Print WayBill">
                    <Button onClick={e => printWayBill(v)}><i className='fas fa-print text-primary' /></Button>
                </Tooltip>
            </Space>
            ];
        });

        const details = utils.getTable(
            [
                <label className='fw-bold'>Driver</label>,
                <label className='fw-bold'>Dispatcher</label>,
                <label className='fw-bold'>TruckNo.</label>,
                <label className='fw-bold'>Qty Dispatched</label>,
                <label className='fw-bold'>Qty Ordered</label>,
                <label className='fw-bold'>Print</label>,
            ],
            data, 'w-100 table table-sm table-striped', 'fw-bold', 'fw-bold', 'fw-bold');


        modal.setTitle(`Waybills for ${record.receipt_no}`);
        modal.setContent(<>
            <label className='h6 text-danger'>Total Quantity Ordered: {parseFloat(totalOrdered)}</label><br />
            <label className='h6 text-danger'>Total Quantity Dispatched: {parseFloat(totalDipatched)}</label><br />
            <label className='h6 text-danger'>Balance: {parseFloat(totalOrdered - totalDipatched)}</label>
            {details}
        </>);
        modal.setOpen(true);
    }

    function printReceiptTempl(v) {
        const company_name = valuesStore.getArrayObjectsValue('settings', 'prop', 'company_name')?.value;
        const branch_name = valuesStore.getArrayObjectsValue('settings', 'prop', 'branch_name')?.value;
        const pobox = valuesStore.getArrayObjectsValue('settings', 'prop', 'pobox')?.value;
        const email = valuesStore.getArrayObjectsValue('settings', 'prop', 'email')?.value;
        const telephone = valuesStore.getArrayObjectsValue('settings', 'prop', 'telephone')?.value;

        const details = utils.getTable(
            [
                <label className='fw-bold'>Item</label>,
                <label className='fw-bold'>Quantity</label>,
                <label className='fw-bold'>Unit Price</label>,
                <label className='fw-bold'>Amount</label>,
                <label className='fw-bold'>Remarks</label>,
            ],
            [
                [v.product_item, v.qty, v.unit_price, v.total_expected_amt, v.remark],
                [null, null, <label className='fw-bold'>Total</label>, <label className='fw-bold'>GHC {v.total_expected_amt}</label>, null]
            ], 'w-100 table table-sm table-striped', 'fw-bold', 'fw-bold', 'fw-bold');


        const jsx = (
            <div className=''>
                <div className='container  fw-bold' ref={printableWayBill}>
                    <div className='row border-bottom'>
                        <div className='col-md-12 border-bottom'>
                            <center><label className='h3'>{company_name} Receipt</label></center>
                        </div>
                        <div className='col-md-3 border-0 border-end img-fluid'>
                            <img style={{ maxHeight: '100%', maxWidth: '100%', marginTop: '-1rem' }} src={`${Settings.logo}/resources/sysimg/receipt_logo.png`} />
                        </div>                       
                        <div className='col-md-5'>
                            <div className='d-flex flex-column align-items-start' style={{fontSize:'12pt'}}>                                
                                <label className='fw-bold'>Receipt No.: {v.receipt_no}</label>                                
                                <label className='fw-bold'>Customer: {v.customer_name}</label>
                                <label className='fw-bold'>Tel: {v.phone_no}</label>
                                <label className='fw-bold'>Date: {v.sales_date}</label>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='d-flex flex-column align-items-start' style={{fontSize:'12pt'}}>
                                <label className='fw-bold'>Branch :{branch_name} </label>
                                <label className='fw-bold'>{pobox}</label>
                                <label className='fw-bold'>{email}</label>
                                <label className='fw-bold'>{telephone}</label>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            {details}
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <ReactToPrint
                        trigger={() => <Button className='amber accent-4 border-0 text-white' icon={<i className='fas fa-print me-1' />}> Print </Button>}
                        content={() => printableWayBill.current}
                    />
                </div>
            </div>
        );
        printModal.setContent(jsx);
        printModal.setOpen(true);
        printModal.setTitle(`Receipt for ${v.receipt_no}`);
    }

    function printWayBill(v) {
        const company_name = valuesStore.getArrayObjectsValue('settings', 'prop', 'company_name')?.value;
        const branch_name = valuesStore.getArrayObjectsValue('settings', 'prop', 'branch_name')?.value;
        const pobox = valuesStore.getArrayObjectsValue('settings', 'prop', 'pobox')?.value;
        const email = valuesStore.getArrayObjectsValue('settings', 'prop', 'email')?.value;
        const telephone = valuesStore.getArrayObjectsValue('settings', 'prop', 'telephone')?.value;

        const details = utils.getTable(
            [
                <label className='fw-bold'>Item</label>,
                <label className='fw-bold'>Qty Dispatched</label>,
                <label className='fw-bold'>Qty Ordered</label>,
                <label className='fw-bold'>Remarks</label>,
            ],
            [[v.product_item, v.dispatched_qty, v.sales_qty, v.remark]], 'w-100 table table-sm table-striped', 'fw-bold', 'fw-bold', 'fw-bold');

        const details2 = utils.getTable(
            null,
            [['Driver', v.driver], ['Truck No.', v.truck_no], ['Dispatched By', v.dispatcher], ['Signature', '']], 'w-100 table table-sm table-striped', 'fw-bold', 'fw-bold', 'fw-bold');

        const jsx = (
            <div className=''>
                <div className='container  fw-bold' ref={printableWayBill}>
                    <div className='row border-bottom'>
                        <div className='col-md-12 border-bottom'>
                            <center><label className='h3'>{company_name} WayBill</label></center>
                        </div>
                        <div className='col-md-3 border-0 border-end img-fluid'>
                            <img style={{ maxHeight: '100%', maxWidth: '100%', marginTop: '-1rem' }} src={`${Settings.logo}/resources/sysimg/receipt_logo.png`} />
                        </div>
                        <div className='col-md-4'>
                            <div className='d-flex flex-column align-items-start'>
                                <label>Branch :{branch_name} </label>
                                <label>{pobox}</label>
                                <label>{email}</label>
                                <label>{telephone}</label>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='d-flex flex-column align-items-start'>
                                <label>Date: {v.date_inserted}</label>
                                <label>Receipt No.: {v.receipt_no}</label>
                                <label>Customer: {v.customer_name}</label>
                                <label>Tel: {v.phone_no}</label>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            {details}
                        </div>
                        <div className='col-md-6'>
                            {details2}
                        </div>
                        <div className='col-md-6 align-self-center'>
                            <em className='h5'>NOTE: Items Sold are NOT Returnable</em>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <ReactToPrint
                        trigger={() => <Button className='amber accent-4 border-0 text-white' icon={<i className='fas fa-print me-1' />}> Print </Button>}
                        content={() => printableWayBill.current}
                    />
                </div>
            </div>
        );
        printModal.setContent(jsx);
        printModal.setOpen(true);
    }

    function balancePayment(record, table = 'BALANCE_PAYMENT') {
        balPayment.setTblName(table);
        balPayment.setShowModal(true);
        balPayment.setSaveCompleted(false);
        balPayment.setRecord({ 'from_where_id': record.receipt_no });
    }

    function delivery(record, table = 'DELIVERY') {
        deliveryAdd.setTblName(table);
        deliveryAdd.setShowModal(true);
        deliveryAdd.setSaveCompleted(false);
        deliveryAdd.setRecord({ 'sales_receipt_no': record.receipt_no });
    }

    function addRecord(tableName = 'sales') {    
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }

    async function deliveryOk() {
        deliveryAdd.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_delivery`, null, deliveryAdd.record);
        if (res.status == 'Ok') {
            deliveryAdd.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        deliveryAdd.setLoading(false);
    }

    async function balPaymentOk() {
        balPayment.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_balance_payment`, null, balPayment.record);
        if (res.status == 'Ok') {
            balPayment.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        balPayment.setLoading(false);
    }

    async function addOnOk() {
        add.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_sales`, null, add.record);
        if (res.status == 'Ok') {
            add.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        add.setLoading(false);
    }

    const colFilters = [
        {
            filter: 'product_item',
            sql: 'SELECT custom_id,product_item FROM products WHERE for_inventory = 0',
            key: 'custom_id',
            value: 'product_item'
        }
    ];

    useMemo(() => {
        table.setColumns(columns);
        table.fetchData();
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')

    }, [add.saveCompleted, del.saveCompleted, deliveryAdd.saveCompleted, balPayment.saveCompleted, table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12'>
                        <Card
                            bordered={false}
                            className="criclebox tablespace border-0 mb-24"
                            title="Sales"
                            extra={
                                <Space>
                                    <Tag color={'red-inverse'}>None</Tag>
                                    <Tag color={'blue-inverse'}>Part</Tag>
                                    <Tag color={'green-inverse'}>Full</Tag>
                                    <DatePicker.RangePicker onChange={v => utils.filterByDate('date_inserted', v, table)} />
                                    <Button className='text-white amber accent-4 border-0' onClick={e => addRecord()}><i className='fas fa-plus me-2' /> New Sale</Button>
                                </Space>
                            }
                        >
                            <div className="table-responsive">
                                {table.table}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {add.addModal('Add Sales', addOnOk)}
            {deliveryAdd.addModal('New Delivery', deliveryOk)}
            {balPayment.addModal('Balance Payment', balPaymentOk)}
            <div className='fw-bold receipt_modal'>
                {modal.modalJSX(null, 600)}
                {printModal.modalJSX(null, 700)}
            </div>
        </>
    );
}

export default memo(Sales);